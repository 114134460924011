export const mediaOrientationsSizes = {
  PORTRAIT: '133.33333%',
  LANDSCAPE: '56.25%',
  SQUARE: '100%',
};

export const HTTP_STATUS_CODES = {
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  MULTIPLE_CHOICE: 300,
  MOVED_PERMANENTLY: 301,
  REDIRECT: 302,
  REDIRECT_TEMPORARY: 307,
  REDIRECT_PERMANENT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  GONE: 410,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
} as const;

export const HEADERS_TO_SET = [
  'content-type',
  'content-length',
  'cache-control',
  'last-modified',
  'server',
];

export const TIME_TO_RESET_MODAL_DATA = 500;

export const ALLOWED_TO_CHECK_STATUSES_FROM_RESPONSES = [500, 200, 404];
