import { DEFAULT_DEV_PFISTER_API_HEADERS } from 'config/api/headers';
import { ApiResponse } from 'types/api';

export const fetchData = async <Response>(
  url: string,
  options: RequestInit = {}
): Promise<ApiResponse<Response>> => {
  try {
    const newUrl = `${process.env.NEXT_PUBLIC_CURRENT_HOST}/api/${url}`;

    const response = await fetch(newUrl, {
      ...options,
      headers: { ...DEFAULT_DEV_PFISTER_API_HEADERS, ...options?.headers },
    });

    const contentType = response.headers.get('content-type');

    const isApplicationJson = contentType?.includes('application/json');

    if (isApplicationJson) {
      return response.json();
    }

    return null;
  } catch (error) {
    console.error(`Error occurred when trying to get data from ${url} \n error-message`, error);

    return error;
  }
};
