import {
  CONTACT_INFORMATION_QUERY,
  DELIVERY_DATES_QUERY,
  DELIVERY_DATES_POSSIBLE_QUERY,
  FLYOUT_QUERY,
  LAYOUT_QUERY,
  ORDER_DETAILS_QUERY,
  PRODUCT_IMAGES_QUERY,
  STORES_AVAILABILITY_QUERY,
  USER_DATA_QUERY,
  RETURN_DETAILS_QUERY,
  FORMS_ORDER_PRODUCTS_QUERY,
} from './index';

export const getLayoutQueryKey = (lang: string) => [LAYOUT_QUERY, lang];
export const getFlyoutQueryKey = (lang: string) => [FLYOUT_QUERY, lang];
export const getUserDataQueryKey = (lang: string) => [USER_DATA_QUERY, lang];
export const getContactInformationQueryKey = (lang: string) => [CONTACT_INFORMATION_QUERY, lang];
export const getDeliveryDatesQueryKey = (orderId: string) => [DELIVERY_DATES_QUERY, orderId];
export const getOrderDetailsQueryKey = (orderId: string) => [ORDER_DETAILS_QUERY, orderId];
export const getReturnDetailsQueryKey = (returnId: string) => [RETURN_DETAILS_QUERY, returnId];
export const getFormsOrderProductsQueryKey = (orderNo: string) => [
  FORMS_ORDER_PRODUCTS_QUERY,
  orderNo,
];
export const getStoresAvailabilityInfoQueryKey = (lang: string) => [
  STORES_AVAILABILITY_QUERY,
  lang,
];
export const getProductImagesQueryKey = (productCodes: string) => [
  PRODUCT_IMAGES_QUERY,
  productCodes,
];
export const getDeliveryDatesPossibleQueryKey = (orderId: string) => [
  DELIVERY_DATES_POSSIBLE_QUERY,
  orderId,
];
