import { FORMS_ROUTE } from 'config/routes';
import { localeSubpaths } from 'constants/translations';

const ROUTE_SLASH = '/';
const PATHNAME_LOCALE_INDEX = 0;

export const isFormsRoute = (path: string): boolean => path?.includes(FORMS_ROUTE);

export const getPathnameWithoutLocale = (): string => {
  const pathnameSplitBySlash = window?.location?.pathname?.split(ROUTE_SLASH) || [];

  if (!pathnameSplitBySlash[0]) {
    pathnameSplitBySlash?.shift();
  }

  if (localeSubpaths[pathnameSplitBySlash[PATHNAME_LOCALE_INDEX]]) {
    pathnameSplitBySlash.shift();
  }

  return pathnameSplitBySlash.join(ROUTE_SLASH);
};
