import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { calculateIfDesktopView } from '@mopfis/scadi/dist/components/header/utils';

import { MAXIMUM_FLYOUT_LENGTH } from '@mopfis/scadi/dist/components/header/state';
import { getFlyoutQueryKey } from 'constants/queries/queryStrings';
import { getFlyoutDataByLang } from 'services/ecom';
import { getQueryConfig } from 'constants/queries/utils';

export const useIsFlyoutInDesktopView = (): boolean => {
  const { locale } = useRouter();

  const { data: flyoutData } = useQuery(
    getFlyoutQueryKey(locale),
    () => getFlyoutDataByLang(locale),
    getQueryConfig(locale)
  );

  const isInDesktopView =
    Array.isArray(flyoutData) && calculateIfDesktopView(flyoutData, MAXIMUM_FLYOUT_LENGTH);

  return isInDesktopView;
};
