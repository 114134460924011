import { getReqConfig } from 'utils/service';
import { ApiResponse } from 'types/api';
import {
  CancelOrderPositionResponse,
  CancelOrderResponse,
  DeliveryDatesResponse,
  MyOrdersResponse,
  OrderDetailsResponse,
  PossibleDeliveryDatesResponse,
} from 'models/orders';
import { FeatureTogglesResponse } from 'models/features';
import { fetchData } from 'services/common';

export const getOrderDetails = async (
  orderId: string
): Promise<ApiResponse<OrderDetailsResponse>> => {
  if (!orderId) {
    console.error('getOrderDetails: orderId is not provided');
    return null;
  }

  const url = `ssp/orders/${orderId}`;
  const reqConfig = getReqConfig('GET');

  return fetchData(url, reqConfig);
};

export const cancelOrderById = async (
  orderId: string
): Promise<ApiResponse<CancelOrderResponse>> => {
  if (!orderId) return null;
  const url = `ssp/orders/${orderId}/cancelOrder`;
  const reqConfig = getReqConfig('GET');

  return fetchData(url, reqConfig);
};

export const cancelOrderPositionById = async (
  orderId: string,
  orderPositionIds: string[]
): Promise<ApiResponse<CancelOrderPositionResponse>> => {
  if (!orderPositionIds?.length || !orderId) return null;
  const url = `ssp/orders/${orderId}/cancelOrderPositions`;
  const reqConfig = getReqConfig('POST', { orderPositionIds });

  return fetchData(url, reqConfig);
};

export const getPossibleDeliveryDates = async (
  orderId: string
): Promise<ApiResponse<PossibleDeliveryDatesResponse>> => {
  if (!orderId) return null;
  const url = `ssp/orders/${orderId}/delivery-date/possible-dates`;
  const reqConfig = getReqConfig('GET');

  return fetchData(url, reqConfig);
};

export const getDeliveryDates = async (
  orderId: string
): Promise<ApiResponse<DeliveryDatesResponse>> => {
  if (!orderId) return null;
  const url = `ssp/orders/${orderId}/delivery-dates`;
  const reqConfig = getReqConfig('GET');

  return fetchData(url, reqConfig);
};

export const changeDeliveryDate = async (
  orderId: string,
  evtNewDelivery: string
): Promise<ApiResponse<DeliveryDatesResponse>> => {
  if (!orderId || !evtNewDelivery) return null;
  const url = `ssp/orders/${orderId}/delivery-dates`;
  const reqConfig = getReqConfig('POST', { evtNewDelivery });

  return fetchData(url, reqConfig);
};

export const getOrdersPdf = async (orderId: string): Promise<ApiResponse<any>> => {
  if (!orderId) return null;
  const url = `${process.env.NEXT_PUBLIC_CURRENT_HOST}/api/download/ssp/orders/${orderId}/pdf`;
  const reqConfig = getReqConfig('GET');

  const response = await fetch(url, reqConfig);

  return response?.blob();
};

export const getOrders = async (): Promise<ApiResponse<MyOrdersResponse>> => {
  const url = 'ssp/orders/my-orders';
  const reqConfig = getReqConfig('GET');

  return fetchData(url, reqConfig);
};
export const getFeatureToggles = async (): Promise<ApiResponse<FeatureTogglesResponse>> => {
  const url = 'ssp/orders/features';
  const reqConfig = getReqConfig('GET');

  return fetchData(url, reqConfig);
};
