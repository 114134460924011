const SQUARE_LOADER_ID = 'square-loader';
const ORDER_INFO_SECTION_ID = 'order-info-section';
const ORDER_TABLE_ITEM_ID = 'order-table-item';
const ORDER_TABLE_HEADER_ID = 'order-table-header';
const PRICE_SUMMARY_ID = 'price-summary';
const ADDRESS_INFO_ID = 'address-info';
const LOADING_WRAPPER_TEST_ID = 'loading-wrapper-test-id';
const ORDER_STEP_TABLE_TEST_ID = 'order-step-table-test-id';
const RETURN_DELIVERY_OPTION_TEST_ID = 'return-delivery-option-test-id';
const SUMMARY_STEP_COMPONENT_TEST_ID = 'summary-step-test-id';
const IMAGE_CELL_TEST_ID = 'image-cell-test-id';
const IMAGE_UPLOADER_TEST_ID = 'image-uploader-test-id';
const NO_RETURN_POSSIBLE_ICON_TEST_ID = 'no-return-possible-icon';
const ORDER_RETURN_TABLE_ITEM_TEST_ID = 'order-return-table-item-test-id';

export {
  SQUARE_LOADER_ID,
  ORDER_TABLE_HEADER_ID,
  ORDER_TABLE_ITEM_ID,
  ORDER_INFO_SECTION_ID,
  PRICE_SUMMARY_ID,
  IMAGE_CELL_TEST_ID,
  IMAGE_UPLOADER_TEST_ID,
  ADDRESS_INFO_ID,
  LOADING_WRAPPER_TEST_ID,
  ORDER_STEP_TABLE_TEST_ID,
  RETURN_DELIVERY_OPTION_TEST_ID,
  SUMMARY_STEP_COMPONENT_TEST_ID,
  ORDER_RETURN_TABLE_ITEM_TEST_ID,
  NO_RETURN_POSSIBLE_ICON_TEST_ID,
};
