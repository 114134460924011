import { isEmptyObject } from '@mopfis/scadi/dist/utils/helpers';
import { HTTP_STATUS_CODES } from 'constants/globals';
import { FORMS_ROUTE } from 'config/routes';
import { getPathnameWithoutLocale } from 'utils/url';

export const getReqConfig = <T, R>(
  method: string,
  body?: T,
  headers?: R
): RequestInit & { headers?: { Cookie?: string } } => {
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    credentials: 'same-origin',
  };

  if (body && !['HEAD', 'GET'].includes(method.toUpperCase()) && !isEmptyObject(body)) {
    options.body = JSON.stringify(body);
  }

  return options;
};

export const createAuthGuard =
  (lang: string) =>
  (response): void => {
    if (response?.errorStatus === HTTP_STATUS_CODES.FORBIDDEN) {
      if (window.location?.pathname?.includes(FORMS_ROUTE)) {
        window.location.replace(
          `${
            process.env.NEXT_PUBLIC_ECOM_LINK
          }/${lang}/self-service-auth?pathname=/${getPathnameWithoutLocale()}`
        );
        return;
      }

      window.location.replace(`${process.env.NEXT_PUBLIC_ECOM_LINK}/${lang}/self-service-auth`);
    }
  };
