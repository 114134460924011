import React, { ReactElement } from 'react';
import { ModalProvider } from 'styled-react-modal';
import dynamic from 'next/dynamic';
import { PfiContainer } from '@mopfis/scadi/dist/components/layout/grid.style';
import { SpecialModalBackground } from '@mopfis/scadi/dist/components/modal-popup/style';

import { useIsFlyoutInDesktopView } from 'components/layout/hooks/use-is-flyout-in-desktop-view';
import { Breadcrumb } from 'components/layout/breadcrumb/breadcrumbs';
import { PfiPageSeo } from 'components/layout/seo';
import { SspNotAvailableComponent } from 'components/layout/ssp-not-available-modal';
import { MainContainer } from './style';
import { LayoutProps } from './type';

const HeaderComponent = dynamic(
  () => import('components/layout/header').then(mod => mod.HeaderComponent),
  {
    ssr: false,
  }
);

export const Footer = dynamic(
  () => import('components/layout/footer').then(mod => mod.FooterComponent),
  {
    ssr: false,
  }
);

const Layout = ({ children }: LayoutProps): ReactElement => {
  const isInDesktopView = useIsFlyoutInDesktopView();

  return (
    <ModalProvider backgroundComponent={SpecialModalBackground}>
      <HeaderComponent />
      <PfiPageSeo />
      <SspNotAvailableComponent />
      <MainContainer isInDesktopView={isInDesktopView}>
        <PfiContainer>
          <Breadcrumb />
          {children}
        </PfiContainer>
      </MainContainer>
      <Footer />
    </ModalProvider>
  );
};

export default Layout;
