const ORDERS_QUERY = 'orders';
const ORDER_DETAILS_QUERY = 'order-details';
const DELIVERY_DATES_POSSIBLE_QUERY = 'delivery-dates-possible';
const DELIVERY_DATES_QUERY = 'delivery-dates';
const LAYOUT_QUERY = 'layout';
const ECOM_CONFIG_QUERY = 'ecom-config';
const FLYOUT_QUERY = 'flyout';
const USER_DATA_QUERY = 'user-data';
const CONTACT_INFORMATION_QUERY = 'contact-information';
const FEATURE_TOGGLES_QUERY = 'feature-toggles';
const FORMS_FEATURE_TOGGLES_QUERY = 'feature-toggles-forms';
const STORES_AVAILABILITY_QUERY = 'stores-availability';
const FORMS_ORDER_PRODUCTS_QUERY = 'forms-order-products';
const PRODUCT_IMAGES_QUERY = 'product-images';
const RETURN_DETAILS_QUERY = 'return-details';
const MY_RETURNS_QUERY = 'my-returns';

const QUERY_CONFIG = {
  refetchOnWindowFocus: false,
  cacheTime: 1000 * 60 * 10,
  staleTime: 1000 * 60 * 10,
  retry: 2,
};

export const PREFETCH_QUERY_CONFIG = {
  ...QUERY_CONFIG,
  staleTime: 60 * 5 * 1000,
};

export {
  ORDERS_QUERY,
  ORDER_DETAILS_QUERY,
  DELIVERY_DATES_POSSIBLE_QUERY,
  DELIVERY_DATES_QUERY,
  LAYOUT_QUERY,
  FLYOUT_QUERY,
  CONTACT_INFORMATION_QUERY,
  ECOM_CONFIG_QUERY,
  FEATURE_TOGGLES_QUERY,
  STORES_AVAILABILITY_QUERY,
  PRODUCT_IMAGES_QUERY,
  QUERY_CONFIG,
  USER_DATA_QUERY,
  RETURN_DETAILS_QUERY,
  MY_RETURNS_QUERY,
  FORMS_FEATURE_TOGGLES_QUERY,
  FORMS_ORDER_PRODUCTS_QUERY,
};
