const ORDER_DETAIL_ROUTE = '/order-details';
const RETURN_DETAILS_ROUTE = '/return-details';
const ORDER_OVERVIEW_ROUTE = '/order-overview';
const ORDER_RETURN_ROUTE = '/order-return';
const MY_RETURNS_ROUTE = '/my-returns';
const FORMS_ROUTE = '/formular';

const ECOM_ACCOUNT_ROUTE = 'konto';
const ECOM_ACCOUNT_LOGIN_ROUTE = 'konto/anmelden';
const ECOM_DE_REFUND_POLICY_ROUTE = '/de/agb#rueckgaberecht';
const ECOM_IT_REFUND_POLICY_ROUTE = '/it/agb#dirittodirestituzione';
const ECOM_FR_REFUND_POLICY_ROUTE = '/fr/agb#droitderetour';
const ECOM_HELP_CENTER_ROUTE = '/hilfecenter';

const HELP_CENTER_FORMS_ROUTES = {
  deliveryAddress: 'lieferadresse-anpassen',
  customerAddress: 'adressaenderung',
  montage: 'montage-aendern',
  addVoucherToOrder: 'rabattcode-aendern',
  payment: 'frage-zu-zahlung',
  name: 'namensaenderung',
  productComplaint: 'produkt-reklamation',
  merchant: 'haendler-lieferanten-anfragen',
  otherTopics: 'sonstige-anfragen',
  customerCard: 'mypfister-mitgliedschaft',
  spareParts: 'ersatzteile-reparaturen',
  complaintService: 'service-reklamation',
  fabricPattern: 'stoffmuster-bestellen',
  carpetService: 'teppich-dienstleistungen',
  supportOnlineShop: 'support-online-shop',
  designCompetition: 'geburtstagstassen',
  voseAppointmentsGerman: 'vorhang-heimberatung',
  voseAppointmentsFrench: 'conseil-rideau-domicile',
  voseAppointmentsItalian: 'consultazione-tende-domici',
} as const;

export {
  ORDER_DETAIL_ROUTE,
  RETURN_DETAILS_ROUTE,
  ORDER_OVERVIEW_ROUTE,
  ORDER_RETURN_ROUTE,
  ECOM_ACCOUNT_ROUTE,
  ECOM_DE_REFUND_POLICY_ROUTE,
  ECOM_IT_REFUND_POLICY_ROUTE,
  ECOM_FR_REFUND_POLICY_ROUTE,
  MY_RETURNS_ROUTE,
  FORMS_ROUTE,
  ECOM_ACCOUNT_LOGIN_ROUTE,
  HELP_CENTER_FORMS_ROUTES,
  ECOM_HELP_CENTER_ROUTE,
};
