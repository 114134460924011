import { MainNavItem } from '@mopfis/scadi/dist/components/header/header-main-nav/main-nav/type';
import { OneSuggest } from '@mopfis/scadi/dist/esm/components/header/header-search/search-form/type';

import { CmsPage } from '@mopfis/scadi/dist/types/CMS';
import { ISuggestions } from '@mopfis/scadi/dist/components/header/header-search/search-form/type';
import { getMappedExtendedSuggestion } from '@mopfis/scadi/dist/utils/header';
import { getReqConfig } from 'utils/service';
import { ConfigData } from 'models/config';
import { formatSuggestions } from 'utils/layout';
import { StoresAvailabilityResponse } from 'models/stores';
import { ApiResponse } from 'types/api';
import { NewsLetterSubscribeProps, User } from 'models/user';
import { fetchData } from 'services/common';
import { ProductFields, ProductImagesResponse } from 'models/product';

export const getFlyoutDataByLang = async (lang: string): Promise<MainNavItem[]> => {
  const url = `ecom/pfisterflyout/${lang || 'de'}-nav.json`;
  const options = getReqConfig('GET');

  return fetchData(url, options);
};

export const getSuggestions = async (query: string, language: string): Promise<OneSuggest[]> => {
  if (!query) {
    return [];
  }

  const res = await fetch(
    `${process.env.NEXT_PUBLIC_CURRENT_HOST}/api/ecom/search/suggestions?term=${query}&lang=${language}`
  );
  const newSuggestions = await res.json();

  return formatSuggestions(newSuggestions);
};

export async function getExtendedSuggestions(
  query: string,
  language: string
): Promise<ISuggestions> {
  if (!query) {
    return {};
  }

  const encodedQuery = encodeURIComponent(query);
  const url = `ecom/search/v2/suggestions?term=${encodedQuery}&lang=${language}`;
  const options = getReqConfig('GET');

  const data = await fetchData<any>(url, options);

  if (typeof data === 'object' && data !== null && Object.keys(data).length > 0) {
    return getMappedExtendedSuggestion(data);
  }

  return {};
}

export const getEcomConfig = async (lang = 'de'): Promise<ConfigData> => {
  const url = `ecom/config?lang=${lang}`;
  const options = getReqConfig('GET');

  return fetchData(url, options);
};

export const getStoresAvailabilityInfo = async (
  locale: string,
  fields: 'FULL' | 'DEFAULT' | 'BASIC' = 'FULL'
): Promise<ApiResponse<StoresAvailabilityResponse>> => {
  const url = `ecom/stores/finder/activeLocations?lang=${locale}&fields=${fields}`;
  const options = getReqConfig('GET');

  return fetchData(url, options);
};

export const getUserDataByLang = async (
  lang = 'de',
  fields: 'FULL' | 'DEFAULT' | 'BASIC' | 'DEBITOR' = 'FULL',
  cookie?: string
): Promise<ApiResponse<User>> => {
  const url = `ecom/users?lang=${lang}&fields=${fields}`;
  const options = getReqConfig('GET');

  if (cookie) {
    options.headers.Cookie = cookie;
  }

  return fetchData(url, options);
};

export const subscribeToNewsLetter = async (
  newsLetterProps: NewsLetterSubscribeProps
): Promise<ApiResponse<User>> => {
  const url = `ecom/subscription/subscribe?baseUrl=${process.env.NEXT_PUBLIC_CURRENT_HOST}`;
  const options = getReqConfig('POST', newsLetterProps, null);

  return fetchData(url, options);
};
export const getLayoutDataByLang = async (
  lang = 'de',
  fields: 'FULL' | 'DEFAULT' | 'BASIC' = 'DEFAULT'
): Promise<CmsPage> => {
  const url = `ecom/cms/layout?lang=${lang}&fields=${fields}`;
  const options = getReqConfig('GET');

  return fetchData(url, options);
};
export const getCmsPageByLabel = async (lang: string, label: string): Promise<CmsPage> => {
  const url = `ecom/cms/pages/label?lang=${lang}&pageLabel=${label}`;
  const options = getReqConfig('GET');

  return fetchData(url, options);
};
export const getProductData = async (
  productCodes: string,
  lang: string | string[],
  fields: ProductFields = 'IMG'
): Promise<ApiResponse<ProductImagesResponse>> => {
  if (!productCodes) return null;

  const url = `ecom/products/bycodes?codes=${productCodes}&lang=${lang}&fields=${fields}`;
  const reqConfig = getReqConfig('GET');

  return fetchData(url, reqConfig);
};
