import { ApiResponse } from 'types/api';
import { getReqConfig } from 'utils/service';
import { NameChangeFormRequestBody } from 'models/forms/name-change';
import { PaymentFormRequestBody } from 'models/forms/payment';
import { MontageFormRequestBody } from 'models/forms/montage';
import { AddVoucherToOrderFormRequestBody } from 'models/forms/add-voucher-to-order';
import { FabricPatternFormRequestBody } from 'models/forms/fabric-pattern';
import { FormsApiResponse } from 'models/forms';
import { CarpetServiceFormRequestBody } from 'models/forms/carpet-service';
import { MerchantFormRequestBody } from 'models/forms/merchant';
import { OtherTopicsFormRequestBody } from 'models/forms/other-topics';
import { CustomerCardFormRequestBody } from 'models/forms/customer-card';
import { SparePartsRepairFormRequestBody } from 'models/forms/spare-parts-repair';
import { DeliveryAddressFormRequestBody } from 'models/forms/delivery-address';
import { ProductComplaintFormRequestBody } from 'models/forms/product-complaint';
import { FormsFeatureTogglesResponse } from 'models/forms/features';
import { ComplaintServiceFormRequestBody } from 'models/forms/complaint-service';
import { SupportOnlineShopFormRequestBody } from 'models/forms/support-online-shop';
import { DesignCompetitionFormRequestBody } from 'models/forms/design-competition';
import { VoseAppointmentFormRequestBody } from 'models/forms/vose-appointment/type';
import { ProductsOrderApiResponse } from 'models/forms/products';
import { fetchData } from 'services/common';

export const changeCustomerName = async (
  customerNameFormBody: NameChangeFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/customer-name`;
  const reqConfig = getReqConfig('POST', customerNameFormBody);

  return fetchData(url, reqConfig);
};

export const sendPaymentFormData = async (
  paymentFormBody: PaymentFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `${process.env.NEXT_PUBLIC_CURRENT_HOST}/api/ssp/forms/payment`;
  const reqConfig = getReqConfig('POST', paymentFormBody);

  return fetchData(url, reqConfig);
};

export const sendAssemblyFormData = async (
  assemblyFormBody: MontageFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/assembly`;
  const reqConfig = getReqConfig('POST', assemblyFormBody);

  return fetchData(url, reqConfig);
};

export const sendVoucherFormData = async (
  voucherFormBody: AddVoucherToOrderFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/voucher`;
  const reqConfig = getReqConfig('POST', voucherFormBody);

  return fetchData(url, reqConfig);
};

export const sendFabricPatternFormData = async (
  fabricPatternFormBody: FabricPatternFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/fabric-pattern`;
  const reqConfig = getReqConfig('POST', fabricPatternFormBody);

  return fetchData(url, reqConfig);
};

export const sendCarpetServiceFormData = async (
  carpetServiceFormBody: CarpetServiceFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/carpet-service`;
  const reqConfig = getReqConfig('POST', carpetServiceFormBody);

  return fetchData(url, reqConfig);
};

export const sendMerchantFormData = async (
  merchantFormBody: MerchantFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/merchant`;
  const reqConfig = getReqConfig('POST', merchantFormBody);

  return fetchData(url, reqConfig);
};

export const sendOtherTopicsFormData = async (
  otherTopicsFormBody: OtherTopicsFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/other-issues`;
  const reqConfig = getReqConfig('POST', otherTopicsFormBody);

  return fetchData(url, reqConfig);
};

export const sendCustomerCardFormData = async (
  customerCardFormBody: CustomerCardFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/loyalty-card`;
  const reqConfig = getReqConfig('POST', customerCardFormBody);

  return fetchData(url, reqConfig);
};

export const sendSparePartsRepairFormData = async (
  sparePartsRepairFormBody: SparePartsRepairFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/spare-parts-repair`;
  const reqConfig = getReqConfig('POST', sparePartsRepairFormBody);

  return fetchData(url, reqConfig);
};

export const sendDeliveryAddressFormData = async (
  deliveryAddressFormBody: DeliveryAddressFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/delivery-address`;
  const reqConfig = getReqConfig('POST', deliveryAddressFormBody);

  return fetchData(url, reqConfig);
};
export const sendProductComplaintFormData = async (
  productComplaintFormBody: ProductComplaintFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/complaint`;
  const reqConfig = getReqConfig('POST', productComplaintFormBody);

  return fetchData(url, reqConfig);
};

export const sendComplaintServiceFormData = async (
  complaintServiceFormBody: ComplaintServiceFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/complaint-service`;
  const reqConfig = getReqConfig('POST', complaintServiceFormBody);

  return fetchData(url, reqConfig);
};

export const getFormsOrderProducts = async <FormsOrderProductsBody>(
  formsOrderProductsBody: FormsOrderProductsBody
): Promise<ProductsOrderApiResponse> => {
  const url = `ssp/forms/products`;
  const reqConfig = getReqConfig('POST', formsOrderProductsBody);

  return fetchData(url, reqConfig);
};

export const sendSupportOnlineShopFormData = async (
  supportOnlineShopBody: SupportOnlineShopFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/support`;
  const reqConfig = getReqConfig('POST', supportOnlineShopBody);

  return fetchData(url, reqConfig);
};

export const getFormsFeatureToggles = async (): Promise<
  ApiResponse<FormsFeatureTogglesResponse>
> => {
  const url = `ssp/forms/features`;
  const reqConfig = getReqConfig('GET');

  return fetchData(url, reqConfig);
};

export const sendDesignCompetitionData = async (
  designCompetitionFormBody: DesignCompetitionFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/birthday-competition`;
  // TODO: recheck where documentId needed
  const { documentId, ...body } = designCompetitionFormBody;
  const reqConfig = getReqConfig('POST', body);

  return fetchData(url, reqConfig);
};

export const sendVoseAppointmentData = async (
  voseAppointmentFormBody: VoseAppointmentFormRequestBody
): Promise<FormsApiResponse> => {
  const url = `ssp/forms/curtain-consultation`;
  const reqConfig = getReqConfig('POST', voseAppointmentFormBody);

  return fetchData(url, reqConfig);
};
