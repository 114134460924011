import { SspBaseApiResponse } from 'types/api';

export interface FeatureTogglesResponse extends SspBaseApiResponse {
  items: FeatureToggles[];
}

export const changeDeliveryAddressFormEnabled = 'changeDeliveryAddressFormEnabled' as const;
export const addOrRemoveAssemblyFormEnabled = 'addOrRemoveAssemblyFormEnabled' as const;

export interface FeatureToggles {
  cancelCompleteOrderEnabled: boolean;
  changeDeliveryDateEnabled: boolean;
  cancelOrderPositionEnabled: boolean;
  returnOrderEnabled: boolean;
  sspShowStornierung: boolean;
  isSspFeatureTogglesFetched: boolean;
  sspEnabled: boolean;
  isEcomConfigFetched: boolean;
  [changeDeliveryAddressFormEnabled]?: boolean;
  isMontageChangeEnabled?: boolean;
  needsAuth: boolean;
  isSspFeatureTogglesFetching: boolean;
}
