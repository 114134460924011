import styled from 'styled-components';
import { Media } from '@mopfis/scadi/dist/config/style';

export const MainContainer = styled.main<{ isInDesktopView: boolean }>`
  padding-top: 6rem;

  @media ${Media.sm} {
    padding-top: 4rem;
  }

  @media ${Media.lg} {
    padding-top: ${props => (props.isInDesktopView ? '11rem' : '4rem')};
  }
`;
