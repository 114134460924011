import React, { ReactElement } from 'react';
import Link from 'next/link';
import { useTransitionFadeUp } from '@mopfis/scadi/dist/animations/transitions/fadeup';

import { useBreadcrumbNodes } from 'components/layout/hooks/use-breadcrumb-nodes';
import { BreadcrumbProps, BreadcrumbNode } from './breadcrumb.type';
import { StyledList, StyledItem, StyledCurrent, StyledLinkWrapper } from './breadcrumbs.style';

export const Breadcrumb = ({ lastItemIsActive = false }: BreadcrumbProps): ReactElement => {
  const { fadeUpEnabled, fadeUpExecute } = useTransitionFadeUp({ fadeUp: false });
  const breadcrumbNodes = useBreadcrumbNodes();

  return (
    <StyledList fadeUpEnabled={fadeUpEnabled} fadeUpExecute={fadeUpExecute}>
      {breadcrumbNodes.map(
        (node: BreadcrumbNode, index: number): JSX.Element => (
          <StyledItem key={node.name}>
            {breadcrumbNodes.length === index + 1 && !lastItemIsActive ? (
              <StyledCurrent>{node.name}</StyledCurrent>
            ) : (
              <StyledLinkWrapper>
                <Link href={node.link}>{node.name}</Link>
              </StyledLinkWrapper>
            )}
          </StyledItem>
        )
      )}
    </StyledList>
  );
};
