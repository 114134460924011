import { useMemo } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import {
  COMMON_NAMESPACE,
  defaultLocale,
  HELP_CENTER_FORM_NAMESPACE,
  localeSubpaths,
  ORDER_DETAILS_NAMESPACE,
  ORDER_OVERVIEW_NAMESPACE,
} from 'constants/translations';
import { HELP_CENTER_FORMS_ROUTES, ORDER_DETAIL_ROUTE, ORDER_OVERVIEW_ROUTE } from 'config/routes';
import { UsePageSeo } from './type';

const PFISTER_SELF_SERVICE_PORTAL = 'Pfister Self Service Portal';

const VOSE_FORM_ROUTES = [
  HELP_CENTER_FORMS_ROUTES.voseAppointmentsGerman,
  HELP_CENTER_FORMS_ROUTES.voseAppointmentsFrench,
  HELP_CENTER_FORMS_ROUTES.voseAppointmentsItalian,
];

export const usePageSeo = (): UsePageSeo => {
  const { pathname, asPath } = useRouter();
  const { t } = useTranslation();

  const languageAlternates = useMemo(
    () => [
      {
        hrefLang: 'x-default',
        href: `${process.env.NEXT_PUBLIC_CURRENT_HOST}/${defaultLocale}${
          pathname && `${pathname}`
        }`,
      },
      ...Object.keys(localeSubpaths).map(localeSubpath => ({
        hrefLang: localeSubpath,
        href: `${process.env.NEXT_PUBLIC_CURRENT_HOST}/${localeSubpath}${
          pathname && `${pathname}`
        }`,
      })),
    ],
    [pathname]
  );

  const pageMetaData = useMemo(() => {
    if (pathname.includes(ORDER_DETAIL_ROUTE)) {
      return {
        title: t(`${COMMON_NAMESPACE}:details-page`),
        description: t(`${ORDER_DETAILS_NAMESPACE}:meta-description`),
      };
    }

    if (pathname.includes(ORDER_OVERVIEW_ROUTE)) {
      return {
        title: t(`${COMMON_NAMESPACE}:order-overview-page`),
        description: t(`${ORDER_OVERVIEW_NAMESPACE}:meta-description`),
      };
    }

    if (VOSE_FORM_ROUTES.some(voseFormRoute => asPath.includes(voseFormRoute))) {
      return {
        title: t(`${HELP_CENTER_FORM_NAMESPACE}:vose-form-meta-title`),
        description: t(`${HELP_CENTER_FORM_NAMESPACE}:vose-form-meta-description`),
      };
    }

    return {
      title: PFISTER_SELF_SERVICE_PORTAL,
      description: PFISTER_SELF_SERVICE_PORTAL,
    };
  }, [t, pathname, asPath]);

  const openGraph = useMemo(
    () => ({
      type: 'website',
      url: `${process.env.NEXT_PUBLIC_CURRENT_HOST}${pathname}`,
      title: pageMetaData.title,
      description: pageMetaData.description,
    }),
    [pageMetaData, pathname]
  );

  const contentUrl = `${process.env.NEXT_PUBLIC_CURRENT_HOST}${pathname}`;

  return {
    openGraph,
    languageAlternates,
    pageMetaData,
    contentUrl,
  };
};
