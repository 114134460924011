import { useMemo } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import {
  MY_RETURNS_ROUTE,
  ORDER_DETAIL_ROUTE,
  ORDER_OVERVIEW_ROUTE,
  ORDER_RETURN_ROUTE,
  RETURN_DETAILS_ROUTE,
} from 'config/routes';
import {
  COMMON_NAMESPACE,
  ORDER_RETURN_NAMESPACE,
  RETURN_DETAILS_NAMESPACE,
} from 'constants/translations';
import { UseBreadcrumbNodes } from './type';

export const useBreadcrumbNodes = (): UseBreadcrumbNodes => {
  const { t } = useTranslation();

  const { pathname, query, locale } = useRouter();
  const { orderId } = query;

  const myAccountBreadcrumbNode = useMemo(
    () => ({
      link: `${process.env.NEXT_PUBLIC_ECOM_LINK}/${locale}/konto`,
      name: t(`${COMMON_NAMESPACE}:my-account-page`),
    }),
    [locale, t]
  );

  const myReturnsBreadcrumbNode = useMemo(
    () => ({
      link: MY_RETURNS_ROUTE,
      name: t(`${COMMON_NAMESPACE}:my-returns-page`),
    }),
    [t]
  );

  const currentBreadcrumbNodes = useMemo(() => {
    const breadcrumbNodes = [
      myAccountBreadcrumbNode,
      {
        link: ORDER_OVERVIEW_ROUTE,
        name: t(`${COMMON_NAMESPACE}:order-overview-page`),
      },
      {
        link: `${ORDER_DETAIL_ROUTE}?orderId=${orderId}`,
        name: t(`${COMMON_NAMESPACE}:details-page`),
      },
      {
        link: `${ORDER_RETURN_ROUTE}?orderId=${orderId}`,
        name: t(`${ORDER_RETURN_NAMESPACE}:return-order`),
      },
    ];

    if (pathname.includes(MY_RETURNS_ROUTE)) {
      return [myAccountBreadcrumbNode, myReturnsBreadcrumbNode];
    }

    if (pathname.includes(RETURN_DETAILS_ROUTE)) {
      return [
        myAccountBreadcrumbNode,
        myReturnsBreadcrumbNode,
        {
          link: RETURN_DETAILS_ROUTE,
          name: t(`${RETURN_DETAILS_NAMESPACE}:return-details`),
        },
      ];
    }

    const currentBreadcrumbNodeIndex = breadcrumbNodes.findIndex(breadcrumbNode =>
      breadcrumbNode.link.includes(pathname)
    );

    return breadcrumbNodes.slice(0, currentBreadcrumbNodeIndex + 1);
  }, [t, locale, orderId, pathname, myAccountBreadcrumbNode, myReturnsBreadcrumbNode]);

  return currentBreadcrumbNodes;
};
