import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import {
  ECOM_CONFIG_QUERY,
  FEATURE_TOGGLES_QUERY,
  FORMS_FEATURE_TOGGLES_QUERY,
} from 'constants/queries';
import {
  addOrRemoveAssemblyFormEnabled,
  changeDeliveryAddressFormEnabled,
  FeatureToggles,
} from 'models/features';
import { getQueryConfig } from 'constants/queries/utils';
import { getEcomConfig } from 'services/ecom';
import { HTTP_STATUS_CODES } from 'constants/globals';
import { isFormsRoute } from 'utils/url';
import { getFormsFeatureToggles } from 'services/help-center-forms';
import { getFeatureToggles } from 'services/orders';

export const useFeatureToggles = (withFormsToggles = false): FeatureToggles => {
  const { locale, asPath } = useRouter();

  const { data, isFetched, isFetching } = useQuery([FEATURE_TOGGLES_QUERY], getFeatureToggles, {
    ...getQueryConfig(locale),
    enabled: !isFormsRoute(asPath),
  });

  const { data: formsFeatureToggleData } = useQuery(
    [FORMS_FEATURE_TOGGLES_QUERY],
    getFormsFeatureToggles,
    {
      ...getQueryConfig(locale),
      enabled: withFormsToggles,
    }
  );

  const isChangeDeliveryAddressFormEnabled = formsFeatureToggleData?.items?.find(
    ({ formName, enabled }) => formName === changeDeliveryAddressFormEnabled && enabled
  )?.enabled;

  const isMontageChangeEnabled = formsFeatureToggleData?.items?.find(
    ({ formName, enabled }) => formName === addOrRemoveAssemblyFormEnabled && enabled
  )?.enabled;

  const { data: ecomConfigData, isFetched: isEcomConfigFetched } = useQuery(
    [ECOM_CONFIG_QUERY],
    () => getEcomConfig(locale),
    getQueryConfig(locale)
  );

  return {
    ...(data?.items && data?.items[0]),
    needsAuth:
      ecomConfigData?.errorStatus === HTTP_STATUS_CODES.FORBIDDEN ||
      data?.errorStatus === HTTP_STATUS_CODES.FORBIDDEN,
    [changeDeliveryAddressFormEnabled]: isChangeDeliveryAddressFormEnabled,
    isMontageChangeEnabled,
    sspShowStornierung: ecomConfigData?.sspShowStornierung,
    sspEnabled: ecomConfigData?.sspEnabled,
    isEcomConfigFetched,
    isSspFeatureTogglesFetched: isFetched,
    isSspFeatureTogglesFetching: isFetching,
  };
};
