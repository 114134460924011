import { OneSuggest } from '@mopfis/scadi/dist/esm/components/header/header-search/search-form/type';

export const formatSuggestions = (suggestions: string[]): OneSuggest[] =>
  suggestions.map((s, i) => ({
    name: s,
    id: i,
  }));

export const redirectToEshopSearchResults = (locale: string, query: string): void => {
  const redirectUrl = `${process.env.NEXT_PUBLIC_ECOM_LINK}/${locale}/suche/${encodeURIComponent(
    query
  )}`;
  window.location.assign(redirectUrl);
};
