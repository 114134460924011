import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Colors } from '@mopfis/scadi/dist/config/style/colors';
import { Easings } from '@mopfis/scadi/dist/config/style/easings';
import { Media } from '@mopfis/scadi/dist/config/style/media';
import { FadeUpProperties, fadeUpStyle } from '@mopfis/scadi/dist/animations/transitions/fadeup';

const breadcrumbTheme = {
  global: css`
    font-family: inherit;
    font-size: 0.9rem;
    font-weight: 500;
  `,
  types: {
    regular: css`
      a {
        color: ${Colors.contrast[60]};
        text-decoration: none;
        transition: 0.3s ${Easings.easeInOutQuad};
        cursor: pointer;
      }

      a:hover {
        color: ${Colors.black[100]};
      }
    `,
    current: css`
      color: ${Colors.contrast[100]};
      text-decoration: none;
    `,
  },
  firstChild: css`
    color: ${Colors.contrast[60]};
    text-decoration: none;
  `,
  list: css`
    margin: 0 0.1rem;
    padding: 0;
    list-style-type: none;
    display: block;
  `,
  item: css`
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    padding-right: 0.75rem;

    :not(:last-child)::after {
      pointer-events: none;
      border: 0 solid ${Colors.contrast[60]};
      border-width: 0 0.0625rem 0.0625rem 0;
      content: '';
      margin: -0.1875rem 0 0 -0.1875rem;
      position: absolute;
      top: 60%;
      right: 0;
      transform: rotate(-45deg);
      transform-origin: 0.1875rem 0.1875rem;
    }

    :first-of-type {
      padding-left: 0.25rem;

      @media ${Media.sm} {
        margin-left: 0.6rem;
        padding-left: 0.75rem;
      }

      @media ${Media.md} {
        margin-left: 0;
        padding-left: 0;
      }
    }
  `,
  itemSize: css`
    /* stylelint-disable no-duplicate-selectors */
    :not(:last-child)::after {
      width: 0.3425rem;
      height: 0.3425rem;
    }
  `,
};

const StyledLinkWrapper = styled.span`
  ${breadcrumbTheme.global}
  ${breadcrumbTheme.types.regular}
`;
const StyledCurrent = styled.span`
  ${breadcrumbTheme.global}
  ${breadcrumbTheme.types.current}
`;

const StyledItem = styled.li`
  ${breadcrumbTheme.item}
  ${breadcrumbTheme.itemSize}
`;

const StyledList = styled.ul`
  ${breadcrumbTheme.list}
  ${({ fadeUpEnabled }: FadeUpProperties): FlattenSimpleInterpolation | null =>
    fadeUpEnabled ? fadeUpStyle.initial : null};
  ${({ fadeUpEnabled, fadeUpExecute }: FadeUpProperties): FlattenSimpleInterpolation | null =>
    fadeUpEnabled && fadeUpExecute ? fadeUpStyle.execute : null};
`;

export { StyledList, StyledItem, StyledCurrent, StyledLinkWrapper };
export default breadcrumbTheme as typeof breadcrumbTheme;
