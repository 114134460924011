import React, { ReactElement } from 'react';
import { Spinner } from '@mopfis/scadi/dist/components/spinner';
import { SQUARE_LOADER_ID } from 'constants/tests/test-ids';
import { SpinnerContainer } from './style';
import { SquareLoaderProps } from './type';

const SquareLoader = ({ isLoading = false }: SquareLoaderProps): ReactElement => {
  if (!isLoading) {
    return null;
  }

  return (
    <SpinnerContainer data-testid={SQUARE_LOADER_ID}>
      <Spinner />
    </SpinnerContainer>
  );
};

export { SquareLoader };
