import styled from 'styled-components';
import { Colors } from '@mopfis/scadi/dist/config/style/colors';

const SpinnerContainer = styled.div`
  position: fixed;
  z-index: 802;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${Colors.white};
  opacity: 0.8;

  > div {
    position: absolute !important;
    top: 50%;
    left: 50%;
  }
`;

export { SpinnerContainer };
