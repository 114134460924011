import React, { ReactElement } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { PfiButton } from '@mopfis/scadi/dist/components/form/button';

import { COMMON_NAMESPACE } from 'constants/translations';
import { useSspNotAvailableComponent } from 'components/layout/hooks/use-ssp-not-available-modal';
import { ModalPopup } from 'components/common/dynamically-loaded-components';
import { SquareLoader } from 'components/loaders/square-loader';
import { ModalError, ButtonWrapper } from './style';

export const SspNotAvailableComponent = (): ReactElement => {
  const { t } = useTranslation();
  const { isErrorModalOpen, handleRedirectToEshop, isLoading } = useSspNotAvailableComponent();

  return (
    <>
      <SquareLoader isLoading={isLoading} />
      <ModalPopup modalState={isErrorModalOpen} toggleModal={handleRedirectToEshop}>
        <ModalError>{t(`${COMMON_NAMESPACE}:ssp-not-available`)}</ModalError>
        <ButtonWrapper>
          <PfiButton variant="secondary" size="large" outlined onClick={handleRedirectToEshop}>
            {t(`${COMMON_NAMESPACE}:button-back-to-shop`)}
          </PfiButton>
        </ButtonWrapper>
      </ModalPopup>
    </>
  );
};
