export const defaultLocale = 'de' as const;

export const IT_LANG = 'it';
export const DE_LANG = defaultLocale;
export const FR_LANG = 'fr';

export const localeSubpaths = {
  de: DE_LANG,
  fr: FR_LANG,
  it: IT_LANG,
};

export const ORDER_OVERVIEW_NAMESPACE = 'order-overview';
export const ORDER_DETAILS_NAMESPACE = 'order-details';
export const ORDER_DETAILS_COMMON_NAMESPACE = 'order-details-common';
export const ORDER_RETURN_NAMESPACE = 'order-return';
export const RETURNS_COMMON_NAMESPACE = 'returns-common';
export const MY_RETURNS_NAMESPACE = 'my-returns';
export const RETURN_DETAILS_NAMESPACE = 'return-details';
export const COMMON_NAMESPACE = 'common';
export const HELP_CENTER_FORM_NAMESPACE = 'help-center-forms';
export const FORMS_COMMON_NAMESPACE = 'forms-common';
