import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useFeatureToggles } from 'hooks/use-feature-toggles';
import { UseSspNotAvailableComponent } from './type';

export const useSspNotAvailableComponent = (): UseSspNotAvailableComponent => {
  const { locale } = useRouter();
  const { isEcomConfigFetched, sspEnabled, needsAuth, isSspFeatureTogglesFetching } =
    useFeatureToggles();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    if (isEcomConfigFetched && !needsAuth && !sspEnabled) {
      setIsErrorModalOpen(true);
    }
  }, [isEcomConfigFetched, sspEnabled]);

  const handleRedirectToEshop = useCallback(
    () => window.location.replace(`${process.env.NEXT_PUBLIC_ECOM_LINK}/${locale}`),
    [locale]
  );

  const isLoading = isSspFeatureTogglesFetching || !isEcomConfigFetched || needsAuth;

  return {
    handleRedirectToEshop,
    isErrorModalOpen,
    isLoading,
  };
};
