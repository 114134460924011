import styled from 'styled-components';

export const ModalError = styled.p`
  text-align: center;
  margin: 1rem auto;
  font-size: 1.2rem;
`;

export const ButtonWrapper = styled.div`
  margin: 1.5rem 0 1rem;
  text-align: center;
`;
