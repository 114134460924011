import { ReactElement } from 'react';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { usePageSeo } from 'components/layout/hooks/use-page-seo';

export const PfiPageSeo = (): ReactElement => {
  const { locale } = useRouter();
  const { languageAlternates, openGraph, pageMetaData, contentUrl } = usePageSeo();

  return (
    <>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no, width=device-width, minimal-ui"
        />
        <meta httpEquiv="Content-Language" content={locale} />
        <meta itemProp="url" content={contentUrl} />
      </Head>
      <NextSeo
        title={pageMetaData.title}
        description={pageMetaData.description}
        openGraph={openGraph}
        languageAlternates={languageAlternates}
      />
    </>
  );
};
